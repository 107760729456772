import './Logo.css'

const Logo = () => {
    return (
        <div className='image-connert'>
            <img src={`${process.env.PUBLIC_URL}/nav_logo.ico`} className="App-logo mt-5" alt="logo" />
        </div>
    )
}

export default Logo