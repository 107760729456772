
import { logout } from "../APIHelper/APIHelper"
import { AiFillHome } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
const NavBarLogOut = () => {
    const navigate =  useNavigate();
    const handleLogout = () => {
        logout()
    }
    const handleHome = () => {
        navigate('/')
    }
    return (
         <div className="row w-100">
            <div className="col-2"> 
                 <div className="text-start py-2 px-5" style={{"cursor":"pointer"}} onClick={handleHome}>
                   <AiFillHome className="text-purpole" size={40}/>

                 </div>
            </div>

            <div className="col-10 d-flex justify-content-end align-items-center"> 
                 {/* <div className=""> */}

                <button className="btn btn-purpole flex items-center me-5 mt-3 px-3 py-2 border rounded text-white bg-blue-700 " onClick={handleLogout}>
                    Logout
                </button>
                {/* </div> */}
            </div>

    </div>
    )
}

export default NavBarLogOut

