import { useState, useContext, useEffect } from "react";
import "./Doctor.css";
import { FaUserDoctor } from "react-icons/fa6";
import { LiaStethoscopeSolid } from "react-icons/lia";
import { DoctorSession } from "./DoctorSession";
import { getData } from "./DoctorData";
// import { Context } from "../Context/Context";
const DoctorList = () => {
  const [ShowSession, SetshowSession] = useState(false);
  const [DoctorData, SetDoctorData] = useState([]);
  const [Doctor, SetDoctor] = useState(0);
  // const { DoctorData , SetDoctorData } = useContext(Context);

  const onclickChannel = (index) => {
    console.log("click");
    SetDoctor(index);
    SetshowSession(true);
  };

  useEffect(() => {
    const Data = getData();
    SetDoctorData(Data);
  }, []);
  return (
    <div className="container">
      <div className="row justify-content-center align-center">
        <h1 className="fs-2 text-purpole mt-4">Doctors</h1>
        <div className="col-12 col-md-6 col-lg-6 d-flex flex-column justify-content-center align-center">
          <ul className="doctorlist mt-3">
            {DoctorData.length > 0 &&
              DoctorData.map((data, index) => {
                return (
                  <li className="doctor-li mt-3">
                    <div className="row justify-content-center align-center">
                      <div className="col-12 col-md-3 col-lg-3 d-flex justify-content-center align-center align-items-center">
                        <div className="icon">
                          <FaUserDoctor className="text-purpole" size={50} />
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-lg-6 d-flex justify-content-center align-center align-items-center">
                        <div className="details">
                          <p className="fs-5 text-purpole">{data.DoctorName}</p>
                          <p className="fs-6 text-black">
                            {data.Specialization}
                          </p>
                        </div>
                      </div>

                      <div className="col-12 col-md-3 col-lg-3 d-flex justify-content-center align-center align-items-center">
                        <div className="channel">
                          <button
                            className="btn btn-purpole"
                            onClick={(e) => onclickChannel(index)}
                          >
                            <LiaStethoscopeSolid className="text-white" />
                            <span>Channel</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </li>
                );
              })}
            {/* <li className="doctor mt-3">
                            <div className='icon'>
                                    <FaUserDoctor className='text-purpole' size={50}/>
                            </div>
                            <div className='details'>
                                <p className='fs-5 text-purpole'>
                                    Prof. ARJUNA DE SILVA
                                </p>
                                <p className='fs-6 text-black'>
                                    ewrewrewqeqre
                                </p>
                            </div>
                            <div className='channel'>
                                <button className='btn btn-purpole' onClick={onclickChannel}>
                                    <LiaStethoscopeSolid className='text-white'/>
                                    <span>
                                        Channel
                                    </span>
                                </button>
                            </div>
                        </li>

                        <li className="doctor mt-3">
                            <div className='icon'>
                                    <FaUserDoctor className='text-purpole' size={50}/>
                            </div>
                            <div className='details text-purpole'>
                                <p className='fs-5'>
                                    Prof. ARJUNA DE SILVA
                                </p>
                                <p className='fs-6 text-black'>
                                    ewrewrewqeqre
                                </p>
                            </div>
                            <div className='channel'>
                                <button className='btn btn-purpole' onClick={onclickChannel}>
                                    <LiaStethoscopeSolid className='text-white'/>
                                    <span>
                                        Channel
                                    </span>
                                </button>
                            </div>
                        </li> */}
          </ul>
        </div>
      </div>
      {ShowSession && (
        <DoctorSession
          sessionshow={ShowSession}
          setsessionshow={SetshowSession}
          Data={DoctorData[Doctor]}
          index={Doctor}
        />
      )}
    </div>
  );
};

export default DoctorList;
