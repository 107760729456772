
// import "./ImageModel.css"
import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import React, { useState} from "react";
import CloseButton from 'react-bootstrap/CloseButton';
// import logo from '../Logo IMG/logo1.png'
// import imageerr from "./Image.svg"
import { FaUserDoctor } from "react-icons/fa6";
import { LiaStethoscopeSolid } from "react-icons/lia";
import { CiBookmark } from "react-icons/ci";
import { DoctorPayment } from "./DoctorPayment";
import './Doctor.css'

export function DoctorSession({sessionshow,setsessionshow,Data, index})
{
    const [show, setShow] = useState(sessionshow);
    const [sessionindex ,  setSessionIndex]  = useState(0);
    const [showPayment, setshowPayment] = useState(false);

    const  handleBookclick = (index) =>
    {
        setshowPayment(true)
        setSessionIndex(index)
    }

    const handleClose = () => {
        setShow(false);
        setsessionshow(false)
      
      };
    return (
        <>
        
          <Modal
            show={show}
            onHide={() => handleClose}
            size="lg"
            // dialogClassName="modal-90w"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header className="bg-white">
              <Modal.Title id="contained-modal-title-vcenter" className="text-purpole">
               Doctor Sessions
              </Modal.Title>
              <CloseButton  onClick={handleClose}/>
            </Modal.Header>
            <Modal.Body className="bg-white">
              <Container className=" justify-content-center">
              <Row className="justify-content-center ">
              <Col s={4} md={4} lg={2} className="justify-content-center align-items-center mt-2">
                    <FaUserDoctor className='text-purpole' size={100}/>
                </Col>

                <Col s={8} md={8} lg={8} className="justify-content-center align-items-center mt-2">
                 <p className='fs-5 text-purpole'>
                                   {Data.DoctorName}
                                </p>
                                <p className='fs-6 text-black'>
                                {Data.Specialization}
                                </p>
                                <p className='fs-6 text-black'>
                                {Data.ContactNumber}
                                </p>
                </Col>
                </Row>
                <Row className="justify-content-center ">
                  <Col s={12} md={12} lg={12} className="justify-content-center align-items-center mt-2">
                  <ul className="doctorlist">
                    {
                        Data&&(
                            Data.BookingAvailableDates.map((session,index)=>{
                                return(
                                    <li className="doctor-li mt-3">
                                    <div className="row justify-content-center align-center">
                                    <div className="col-12 col-md-4 col-lg-4 d-flex justify-content-center align-center align-items-center">
                                        <div className='details'>
                                                <p className="fs-6 text-black">
                                                    {session.Date}
                                                    {/* JUNE 16 2024 */}
                                                </p>
            
                                                <p className="fs-5 text-purpole">
                                                    {session.Times}
                                                    {/* Sun 10:00 AM */}
                                                </p>
                                        </div>
                                        </div>
            
                                        <div className="col-12 col-md-4 col-lg-4 d-flex justify-content-center align-center align-items-center">
                                        <div className='details'>
                                            <p className='fs-6 text-black'>
                                                ACTIVE APPOINTMENTS
                                            </p>
                                            <p className='fs-4 text-purpole text-center'>
                                            {session.ActiveAppointments}
                                            </p>
                                        </div>
                                        </div>
            
                                        <div className="col-12 col-md-2 col-lg-2 d-flex justify-content-center align-center align-items-center">
                                        
                                        <div className='channel'>
                                            <button className='btn btn-purpole' onClick={()=> handleBookclick(index)}>
                                                <CiBookmark className='text-white' />
                                                <span>
                                                     BOOK
                                                </span>
                                            </button>
                                        </div>
                                        </div>
            
                                        <div className="col-12 col-md-2 col-lg-2 d-flex justify-content-center align-center align-items-center">
                                        <div className='details text-center text-black'>
                                          AVILABLE
                                        </div>
                                        </div>
                                        </div>
                                    </li>
                                )
                            })
                        )
                    }
                    
                      </ul>
                  </Col>
                </Row>
              </Container>
            </Modal.Body>
          </Modal>


          {
            showPayment&&(
                <DoctorPayment sessionPayment={showPayment}  setPaymentshow={setshowPayment}  Data={Data} sessionindex={sessionindex}/>
            )
          }
        </>
      );
}