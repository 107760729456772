import { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Login.css";
import { useContext } from "react";
import { Context } from "../../Context/Context";
import { login } from "../../APIHelper/APIHelper";
import { IoPersonCircle, IoPerson } from "react-icons/io5";
import { HiLockClosed } from "react-icons/hi";
import toast,{ Toaster  } from "react-hot-toast";
import Logo from "../../Utility/Logo/Logo";

const Login = () => {
    const navigate =  useNavigate();

    const [username, setusername] = useState("");
    const [password, setpassword] = useState("");
    const {setIsLoading} = useContext(Context);


 const clickhandler = async () => {
    if(username === "" || password === ""){
      toast.error("Please fill all the fields")
       // alert("Please fill all the fields");
    }
    else{
        setIsLoading(true);
        const res = await login(username,password);
        setIsLoading(false);
        
        console.log("login res", res)
        if(res=== undefined)
        {
          toast.error("something went wrong! try agin")
         
          
          
        }
        else if(res.data.message !== undefined)
        {
          toast.error(res.data.message)
        }
        else{
          navigate("/")
        }

        // console.log(res);

    }
 }
  return (
    <div className="container mt-5">
      <div className="row justify-content-center align-center mt-5">
        <div className="col-12 col-md-8 col-lg-5 text-center shadow">
         
          <div className=" d-flex justify-content-center align-center">
            {/* <IoPersonCircle size={120} /> */}
            {/* <i class="counter-icon flaticon-dog-with-first-aid-kit-bag text-purpole"></i>
             */}
             <Logo />
          </div>

          <div className="text-center mb-5">
            <h1 className="text-center fs-2 text-purpole">Login</h1>
            {/* <hr className="w-100 mx-auto text-black" /> */}
          </div>

          <div className="row justify-content-center align-center">
            <div className="col-12 col-md-10 col-lg-10 text-center">
              <div className="input-group flex- mt-3">
                <label className="input-group-text" htmlFor="username">
                  <IoPerson />
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="E-mail"
                  aria-label="Username"
                  id="username"
                  onChange={(e) => setusername(e.target.value)}
                />
              </div>

              <div className="input-group flex-nowrap mt-3">
                <label className="input-group-text" htmlFor="username">
                  <HiLockClosed />
                </label>
                <input
                  type="password"
                  className="form-control"
                  placeholder="Password"
                  aria-label="Password"
                  id="Password"
                  onChange={(e) => setpassword(e.target.value)}
                />
              </div>
            </div>
          </div>

          <div className="row justify-content-center align-center">
            <div className="mt-5 mb-3">
              <button type="button" className="btn btn-purpole w-50 fs-5" onClick={clickhandler}>
                Login
              </button>
            </div>
          </div>

          <div className="row justify-content-center align-center">
            <div className="mt-3 mb-3">
              <a href="/register" className="link-purpole">Don't have an account? Register</a>
            </div>
          </div>
        </div>
      </div>
      <Toaster />
    </div>
  );
};

export default Login;
