// import logo from '../../Crop Component/Logo/logo.png'
// import Logo from "../../Utility/Logo/Logo";
// import "../../../App.css";
// import { From } from "../../Form/Form";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { verifyuser } from "../APIHelper/APIHelper";
import {From} from '../Form/Form'
// import { FaUserLarge } from "react-icons/fa6";
import { PiDog } from "react-icons/pi";
import { useContext } from 'react';
import {Context} from '../Context/Context'
import "./Question.css";

const Questions = () => {
  const navigate = useNavigate();

  const {setfinalAnswers } = useContext(Context);

 const PetDetils  =  {
    "Dog": [
      "Labrador Retriever",
      "German Shepherd",
      "Golden Retriever",
      "Bulldog",
      "Beagle",
      "Poodle",
      "Dachshund",
      "Boxer",
      "Shih Tzu",
      "Chihuahua",
      "Husky"
    ],
    "Cat": [
      "Domestic Shorthair",
      "Maine Coon",
      "Siamese",
      "Persian",
      "Ragdoll"
    ],
    "Rabbit": [
      "Holland Lop",
      "Netherland Dwarf",
      "Mini Rex"
    ],
    "GuineaPig": [
      "American",
      "Abyssinian",
      "Peruvian"
    ],
    "Ferret": [
      "Standard Ferret",
      "Angora Ferret"]
  }

  const selectobj = {
    name:'',
    Age:'',
    Shape:'',
    Moisture:'',
    Odor:'',
    Development:'',
    Location:'',
    Parasites:'',
    Behavior:'',
    HairLoss:'',
    Underneath:''
  }

  // const [DogType , SetDogType] =  useState('');
  const [IsUpload,setIsupload] =  useState(false)
  const [Answers, setAnswers] =   useState(selectobj)
  const [emptyProps, setEmptyProps] = useState([]);
  const scanHanddle = () => {
    navigate("home");
  };

  // const onPetTypeChange = (e)=>{
  //   console.log(e.target.value)
  //   SetDogType(e.target.value)
  // }

  const handleNextStep=(e)=>{
    console.log("handdle")
    e.preventDefault()

    const empty =  findEmptyProperties(Answers)
    if(empty.length>0)
    {
      console.log(empty)
      for (let key of empty) 
      {
        console.log('key' ,  key)
       let elemet  =  document.getElementById(key)
       if(elemet!= null)
       {
        elemet.style="border:2px solid red;"
       }
       console.log("eleemnt ",elemet)

       
      //  .style="border:2px solid red;"
      }
    }
    else{
      setfinalAnswers(Answers)
      setIsupload(true)
    }
    

  }

  const AnswersOnchnage = (e)=>{
    const temp =  Answers;
    const id = e.target.id;
    temp[id] =  e.target.value;

    
    setAnswers(temp)
    let elemet  =  document.getElementById(id)
    if(elemet!= null)
    {
     elemet.style="border:"
    }
  }

  function findEmptyProperties(obj) {
    let emptyProperties = [];
    for (let key in obj) {
        if (obj[key] === "") {
            emptyProperties.push(key);
        }
    }
    return emptyProperties;
}


  useEffect(() => {
    const res = verifyuser();
    console.log(res);
    if (!res) {
      navigate("login");
    }
  }, []);

  useEffect(() => {
    const findEmptyProperties = (obj) => {
        let emptyProperties = [];
        for (let key in obj) {
            if (obj[key] === "") {
                emptyProperties.push(key);
            }
        }
        return emptyProperties;
    };

    setEmptyProps(findEmptyProperties(Answers));
}, [Answers]);

  // const isEmpty = (id) => emptyProps.includes(id);

  return (
    <div className="container">
      <div className="row justify-content-center align-items-center  text-center mb-5">
        <div className="col-12 col-md-12 col-lg-12">
          {!IsUpload && (
            <div className="row justify-content-center align-items-center text-start mt-4">
              <form>
                {/* <div className="col-12 col-md-5 col-lg-5 text-black"> */}
                <div class="mb-3 row">
                  <label
                    for="name"
                    class="col-sm-5 col-form-label text-black fs-5"
                  >
                    Pet Name
                  </label>
                  <div class="col-sm-5">
                    <input
                      type="text"
                      class="form-control"
                      onChange={AnswersOnchnage}
                      id="name"
                      placeholder="Enter your pet name"
                      required
                    />
                  </div>
                </div>

                <div class="mb-3 row">
                  <label
                    for="Age"
                    class="col-sm-5 col-form-label text-black fs-5"
                  >
                    Age of dog
                  </label>
                  <div class="col-sm-5">
                    <select
                      class="form-select"
                      id="Age"
                      onChange={AnswersOnchnage}
                      aria-label="Floating label select example"
                      //   style={{
                      //     border: isEmpty("Age") ? '2px solid red' : '1px solid black'
                      // }}
                      placeholder="please select"
                      required
                    >
                      <option selected disabled>
                        Please select
                      </option>
                      <option value="Less than 1 year">Less than 1 year</option>
                      <option value="1-3 years">1-3 years</option>
                      <option value="4-6 years">4-6 years</option>
                      <option value="7-9 years">7-9 years</option>
                      <option value="10+ years">10+ years</option>
                    </select>
                  </div>
                </div>

                <div class="mb-3 row">
                  <label
                    for="Shape"
                    class="col-sm-5 col-form-label text-black fs-5"
                  >
                    Shape of the Rash
                  </label>
                  <div class="col-sm-5">
                    <select
                      class="form-select"
                      id="Shape"
                      onChange={AnswersOnchnage}
                      aria-label="Floating label select example"
                      placeholder="please select"
                      required
                    >
                      <option selected disabled>
                        Please select
                      </option>
                      <option value="No rash">No rash</option>
                      <option value="Circular">Circular</option>
                      <option value="Irregular">Irregular</option>
                      <option value="Not sure">Not sure</option>
                    </select>
                  </div>
                </div>

                <div class="mb-3 row">
                  <label
                    for="Moisture"
                    class="col-sm-5 col-form-label text-black fs-5"
                  >
                    Moisture of the Rash
                  </label>
                  <div class="col-sm-5">
                    <select
                      class="form-select"
                      id="Moisture"
                      onChange={AnswersOnchnage}
                      aria-label="Floating label select example"
                      placeholder="Please select"
                      required
                    >
                      <option selected disabled>
                        Please select
                      </option>
                      <option value="Dry">Dry</option>
                      <option value="Moist"> Moist</option>
                      <option value="Oozing">Oozing</option>
                      <option value="Not sure">Not sure</option>
                    </select>
                  </div>
                </div>

                <div class="mb-3 row">
                  <label
                    for="Odor"
                    class="col-sm-5 col-form-label text-black fs-5"
                  >
                    Odor from the Rash
                  </label>
                  <div class="col-sm-5">
                    <select
                      class="form-select"
                      id="Odor"
                      onChange={AnswersOnchnage}
                      aria-label="Floating label select example"
                      placeholder="Please select"
                      required
                    >
                      <option selected disabled>
                        Please select
                      </option>
                      <option value="No significant odor">
                        No significant odor
                      </option>
                      <option value="Foul odor">Foul odor</option>
                      <option value="Not sure">Not sure</option>
                    </select>
                  </div>
                </div>

                <div class="mb-3 row">
                  <label
                    for="Development"
                    class="col-sm-5 col-form-label text-black fs-5"
                  >
                    Development Speed of the Rash
                  </label>
                  <div class="col-sm-5">
                    <select
                      class="form-select"
                      id="Development"
                      onChange={AnswersOnchnage}
                      aria-label="Floating label select example"
                      placeholder="Please select"
                      required
                    >
                      <option selected disabled>
                        Please select
                      </option>
                      <option value="Rapid onset (hours to a couple of days)">
                        Rapid onset (hours to a couple of days)
                      </option>
                      <option value="Gradual onset (several days to weeks)">
                        Gradual onset (several days to weeks)
                      </option>
                      <option value="Slow onset (over weeks to months)">
                        Slow onset (over weeks to months)
                      </option>
                      <option value="No development">No development</option>
                    </select>
                  </div>
                </div>

                <div class="mb-3 row">
                  <label
                    for="Location"
                    class="col-sm-5 col-form-label text-black fs-5"
                  >
                    Location of the Rash
                  </label>
                  <div class="col-sm-5">
                    <select
                      class="form-select"
                      id="Location"
                      onChange={AnswersOnchnage}
                      aria-label="Floating label select example"
                      placeholder="Please select"
                      required
                    >
                      <option selected disabled>
                        Please select
                      </option>
                      <option value="Head and neck">Head and neck</option>
                      <option value="Back">Back</option>
                      <option value="Limbs">Limbs</option>
                      <option value="Underbelly">Underbelly</option>
                      <option value="Generalized">Generalized</option>
                    </select>
                  </div>
                </div>

                <div class="mb-3 row">
                  <label
                    for="Parasites"
                    class="col-sm-5 col-form-label text-black fs-5"
                  >
                    Presence of Parasites
                  </label>
                  <div class="col-sm-5">
                    <select
                      class="form-select"
                      id="Parasites"
                      onChange={AnswersOnchnage}
                      aria-label="Floating label select example"
                      placeholder="Please select"
                      required
                    >
                      <option selected disabled>
                        Please select
                      </option>
                      <option value="Ticks/Fleas">Ticks/Fleas</option>
                      <option value="Absent">Absent</option>
                    </select>
                  </div>
                </div>

                <div class="mb-3 row">
                  <label
                    for="Behavior"
                    class="col-sm-5 col-form-label text-black fs-5"
                  >
                    Dog’s Behavior
                  </label>
                  <div class="col-sm-5">
                    <select
                      class="form-select"
                      id="Behavior"
                      onChange={AnswersOnchnage}
                      aria-label="Floating label select example"
                      placeholder="Please select"
                      required
                    >
                      <option selected disabled>
                        Please select
                      </option>
                      <option value="Scratching frequently">
                        Scratching frequently
                      </option>
                      <option value="Licking or biting affected area">
                        Licking or biting affected area
                      </option>
                      <option value="No change in behavior">
                        No change in behavior
                      </option>
                    </select>
                  </div>
                </div>

                <div class="mb-3 row">
                  <label
                    for="HairLoss"
                    class="col-sm-5 col-form-label text-black fs-5"
                  >
                    Hair Loss
                  </label>
                  <div class="col-sm-5">
                    <select
                      class="form-select"
                      id="HairLoss"
                      onChange={AnswersOnchnage}
                      aria-label="Floating label select example"
                      placeholder="Please select"
                      required
                    >
                      <option selected disabled>
                        Please select
                      </option>
                      <option value="No hair loss">No hair loss</option>
                      <option value="Hair loss around rash">
                        Hair loss around rash
                      </option>
                      <option value="Widespread hair loss">
                        Widespread hair loss
                      </option>
                      {/* <option value="Multiple Patches of Hair Loss">Multiple Patches of Hair Loss</option> */}
                      <option value="Not sure">Not sure</option>
                    </select>
                  </div>
                </div>

                <div class="mb-3 row">
                  <label
                    for="Underneath"
                    class="col-sm-5 col-form-label text-black fs-5"
                  >
                    Condition of Skin Underneath
                  </label>
                  <div class="col-sm-5">
                    <select
                      class="form-select"
                      id="Underneath"
                      onChange={AnswersOnchnage}
                      aria-label="Floating label select example"
                      placeholder="Please select"
                      required
                    >
                      <option selected disabled>
                        Please select
                      </option>
                      <option value="No visible conditions">
                        No visible conditions
                      </option>
                      <option value="Red and inflamed">Red and inflamed</option>
                      <option value="Mild Red">Mild Red</option>
                      <option value="Flaky or scaly">Flaky or scaly</option>
                      <option value="Not sure">Not sure</option>
                    </select>
                  </div>
                </div>

                {/* <div className="row justify-content-center align-items-center">
                <p className="fs-5 text-black"> Does your dog has any of these conditions?</p>
                <div className="col-sm-2">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      id="Diabetics"
                     
                    />
                    <label
                      class="form-check-label text-purpole fs-5"
                      for="Diabetics"
                    >
                      Diabetics
                    </label>
                  </div>
                </div>


                <div className="col-sm-2">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      id="Allergies"
                      
                    />
                    <label
                      class="form-check-label text-purpole fs-5"
                      for="Allergies"
                    >
                      Allergies
                    </label>
                  </div>
                </div>


                <div className="col-sm-2">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      id="Obesity"
                     
                    />
                    <label
                      class="form-check-label text-purpole fs-5"
                      for="Obesity"
                    >
                      Obesity
                    </label>
                  </div>
                </div>

               
               
                <div className="col-sm-3">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      id="Cancer"
                    />
                    <label
                      class="form-check-label text-purpole fs-5"
                      for="Cancer"
                    >
                     Cancer
                    </label>
                  </div>
                </div>


                
                <div className="col-sm-4">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      id="Problems"
                    />
                    <label
                      class="form-check-label text-purpole fs-5"
                      for="Problems"
                    >
                     Thyroid Problems
                    </label>
                  </div>
                </div>

                
                <div className="col-sm-4">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      id="Heart"
                    />
                    <label
                      class="form-check-label text-purpole fs-5"
                      for="Heart"
                    >
                    Heart Disease
                    </label>
                  </div>
                </div>

            
              </div> */}

                <div className="row justify-content-center align-items-center">
                  <div className="col-12 col-md-4 col-lg-4">
                    <button
                      type="submit"
                      className="btn btn-purpole mt-5 mb-4 w-100"
                      onClick={handleNextStep}
                    >
                      Next Step
                    </button>
                  </div>
                </div>
              </form>
            </div>
          )}

          {IsUpload && <From />}
        </div>
      </div>
      {/* </div> */}
    </div>
  );
};

export default Questions;
