import axios from "axios";
import { Navigate } from "react-router-dom";
import toast from "react-hot-toast";

// const BaseURL = process.env.REACT_APP_BASE_URL;
const BaseURL = 'https://api.stubby.online/';
// const BaseURL = 'http://127.0.0.1:5000';
console.log(BaseURL);

export const login = async (username, password) => {
  try {
    const res = await axios.post(BaseURL + "/api/auth/login", {
      email: username,
      password: password,
    });

    console.log("status ", res);
    console.log("token ", res.data);
    if (res.data.jwt_token !== undefined) {
      storeToken(res.data.jwt_token);
    }

    return res;
  } catch (err) {
    console.log(err);
    // return err;
    // return errorhandding(err);
  }
};

export const register = async (username, password, confirme_password) => {
  try {
    const res = await axios.post(BaseURL + "/api/auth/register", {
      email: username,
      password: password,
      confirm_password: confirme_password,
    });
    return res;
  } catch (err) {
    console.log(err);
    // return errorhandding(err);
    // return "somthing wentwrong";
  }
};

export const logout = async () => {
  localStorage.removeItem("token");
  console.log("logout");
  window.location.replace("/login");
  Navigate("/login");
};

const storeToken = (data) => {
  localStorage.setItem("token", data);
};

export const getToken = () => {
  return localStorage.getItem("token");
};

export const verifyuser = () => {
  const data = getToken();
  if (data === null || data === undefined) {
    return false;
  } else {
    return true;
  }
};

const errorhandding = (res) => {
  debugger;
  // const navigate = Navigate()
  let message = "";
  const error = res.data.status;
  console.log(res.data.status);
  if (res.data.status !== undefined) {
    if (error === 500) {
      message = "Server is not availble or error encounterd";

      // navigate("/login")
    } else if (error === 404) {
      message = "Not found";
      // navigate("/login")
    } else if (error === 401) {
      message = "You Don't have permission";
      // navigate("/login")
    }
  }

  if (message !== "") {
    console.log("error message", message);
    toast.error(message);
    return;
  }

  return res;
};
