// import logo from "../Logo/logo.png"
import "./Form.css";
import { useState,useEffect } from "react";
import CropModel from "../Crop Component/CropModel";
import { ImageModel } from "../Imagemodel/ImageModel";
import { ModelSelect } from "../SelectModal/ImageModel";

import { Instruction } from "../InstructionModel/InstructionModel"; 
import ResultPage from "../Pages/Result Page/Result";
import { useContext } from 'react';
import {Context} from '../Context/Context'





export function LoadingSpinner() {
  return (
    <div className="spinner-container">
      <div className="loading-spinner"></div>
    </div>
  );
}

export function From() {

  const {resetSetting,
    uploadImage, 
    setUploadImage,
    Result,
    responseImage,
    onshow, setonshow,
    CropImage, SetCropImage,
    imgDimention, setimgDimention,
    setPain,
    Imageshow, setImageshow,
    instruction,setinstuction,
    showselect,setshowselect,
    isLoading} = useContext(Context)



  const handleChangesPain = (e)=>
    {   
        setPain(e.target.value);
    }




  // validate the images
  const imgValidation = (imageURL) => {
    setonshow(false);
    setImageshow(false);
    let file, img;
    file = imageURL
    if (file) {
      img = new Image();
      let objectUrl = URL.createObjectURL(file);
      img.onload = function () {
        setimgDimention({
          width: this.width,
          height: this.height,
        });

        if(this.width>150&&this.height>150)
        {
          // setonshow(true)
          setImageshow(false)
          setshowselect(true)
        }
        else
        {
          // setonshow(false)
          setImageshow(true)
          setshowselect(false)
        }
       
        URL.revokeObjectURL(objectUrl);
      };
      img.src = objectUrl;
    }
  };

  // const resetSetting = () => {
  //   setresponseImage(null);
  //   SetResult(null);
  // };

  const imageChange = (e) => {
    setUploadImage(null);
    resetSetting();

    if (e.target.files && e.target.files.length > 0) {
      setUploadImage(e.target.files[0]);
      SetCropImage(null);
      imgValidation(e.target.files[0]);
      e.target.value = null; 
    }
  };

  useEffect(()=>
  {
    const delay = 1;
    let timer1 = setTimeout(() => setinstuction(true),delay * 1000);
    return () => {
      clearTimeout(timer1);
    };
  },[])

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="row justify-content-center">
          <div className="col-sm-12 col-lg-8 col-md-6 align-item-center justify-content-center">
            <form>
{/* 
            <div className="col-sm-12 col-lg-6 col-md-12 align-self-center">
                                 <label htmlFor="Select" className="form-label s-4">Do you have Pain ?</label>
                           <div className="input-group mb-3">
                            <select className="form-select" id="Select" aria-label="Default select example" onChange={handleChangesPain} required>
                                <option selected>Select</option>
                                <option Value={1}>Yes</option>
                                <option Value={0}>No</option>
                            </select>
                        </div>
             </div> */}
            <div className="col-sm-12 col-lg-12 col-md-12 align-self-center">
              <label
                htmlFor="Image"
                className="form-label jutify-content-start s-4"
              >
                Upload Your Image
              </label>
              <div className="input-group mb-3">
                <input
                  type="file"
                  className="form-control z-1"
                  id="Image"
                  accept="image/*"
                  aria-describedby="basic-addon3"
                  onChange={imageChange}
                />
              </div>
              </div>
            



              {onshow && !Imageshow ? (
                <CropModel
                  UpImage={URL.createObjectURL(uploadImage)}
                />
              ) : (
                ""
              )}

              {showselect && !Imageshow ? (
                <ModelSelect />
              ) : (
                ""
              )}


              {Imageshow && !onshow ?(<ImageModel  ImageShow={Imageshow} ImageDimention={imgDimention} />):""}

            </form>
          </div>
        </div>
 
       
           {instruction && <Instruction onshow={instruction} setWarnning={setinstuction} />}



      </div>
   
    </div>
  );
}
