import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Context } from "../../Context/Context";
import { register } from "../../APIHelper/APIHelper";
import { IoPerson } from "react-icons/io5";
import { HiLockClosed } from "react-icons/hi";
import "./register.css";
import toast, { Toaster } from "react-hot-toast";
import Logo from "../../Utility/Logo/Logo";

const Register = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const { setIsLoading } = useContext(Context);

  // Email validation function using regex
  const isValidEmail = (email) => {
    // Simple regex for email validation
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const clickHandler = async () => {
    // Trim inputs to remove unnecessary whitespace
    const trimmedUsername = username.trim();
    const trimmedPassword = password.trim();
    const trimmedConfirmPass = confirmPass.trim();

    // Check for empty fields
    if (!trimmedUsername || !trimmedPassword || !trimmedConfirmPass) {
      toast.error("Please fill all the fields");
      return;
    }

    // Validate email format
    if (!isValidEmail(trimmedUsername)) {
      toast.error("Please enter a valid email address");
      return;
    }

    // Check if passwords match
    if (trimmedPassword !== trimmedConfirmPass) {
      toast.error("Passwords do not match");
      return;
    }

    // Proceed with registration
    try {
      setIsLoading(true);
      const res = await register(trimmedUsername, trimmedPassword, trimmedConfirmPass);
      console.log(res);

      if (!res) {
        toast.error("Something went wrong! Please try again");
      } else if (res.data.message) {
        toast.error(res.data.message);
      } else {
        toast.success("Thanks for signing up!");
        toast.success("We will get back to you with your login credentials!");

        // Reset input fields after successful registration
        setUsername("");
        setPassword("");
        setConfirmPass("");

        // Optionally navigate to login page after successful registration
        // navigate("/login");
      }
    } catch (error) {
      console.error("Registration error:", error);
      toast.error("An unexpected error occurred. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="container mt-5">
      <div className="row justify-content-center align-items-center mt-5">
        <div className="col-12 col-md-8 col-lg-5 text-center shadow p-4 rounded">

          {/* Logo Section */}
          <div className="d-flex justify-content-center align-items-center mb-3">
            <Logo />
          </div>
          
          {/* Header */}
          <h1 className="fs-2 text-purpole mb-4">Register</h1>

          {/* Input Fields */}
          <div className="row justify-content-center">
            <div className="col-12 col-md-10 col-lg-10 text-center">
              
              {/* Email Input */}
              <div className="input-group flex-nowrap mt-3">
                <span className="input-group-text" htmlFor="username">
                  <IoPerson />
                </span>
                <input
                  type="email"
                  className="form-control"
                  placeholder="E-mail"
                  aria-label="Username"
                  id="username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </div>

              {/* Password Input */}
              <div className="input-group flex-nowrap mt-3">
                <span className="input-group-text" htmlFor="Password">
                  <HiLockClosed />
                </span>
                <input
                  type="password"
                  className="form-control"
                  placeholder="Password"
                  aria-label="Password"
                  id="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>

              {/* Confirm Password Input */}
              <div className="input-group flex-nowrap mt-3">
                <span className="input-group-text" htmlFor="ConfirmPassword">
                  <HiLockClosed />
                </span>
                <input
                  type="password"
                  className="form-control"
                  placeholder="Confirm Password"
                  aria-label="Confirm Password"
                  id="ConfirmPassword"
                  value={confirmPass}
                  onChange={(e) => setConfirmPass(e.target.value)}
                />
              </div>
            </div>
          </div>

          {/* Register Button */}
          <div className="row justify-content-center">
            <div className="mt-5 mb-3 w-100">
              <button
                type="button"
                className="btn btn-purpole w-100 fs-5"
                onClick={clickHandler}
              >
                Register
              </button>
            </div>
          </div>

          {/* Login Link */}
          <div className="row justify-content-center">
            <div className="mt-3 mb-3">
              <a href="/login" className="link-purpole">
                Already have an account? Login
              </a>
            </div>
          </div>
        </div>
      </div>
      <Toaster />
    </div>
  );
};

export default Register;
