// Function to format the date to "MONTH DAY YEAR"
function formatDate(date) {
  const options = { year: "numeric", month: "long", day: "numeric" };
  return new Date(date).toLocaleDateString("en-US", options).toUpperCase();
}

// Function to format the time to "Day TIME"
function formatTime(date) {
  const options = {
    weekday: "short",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };
  return new Date(date).toLocaleDateString("en-US", options).toUpperCase();
}

// Function to get future dates from today
function getFutureDate(days) {
  const today = new Date();
  today.setDate(today.getDate() + days);
  return today.toISOString().split("T")[0];
}

function verifyTimeFormat(timeString) {
    // Regular expression pattern to match the time format
    const pattern = /^(SUN|MON|TUE|WED|THU|FRI|SAT)\s([1-9]|1[0-2]):([0-5][0-9])\s(AM|PM)$/;
  
    // Check if the time string matches the pattern
    return pattern.test(timeString);
  }

export const appointments = [
  {
    ID: 0,
    DoctorName: "Dr. Nimal Perera",
    Specialization: "Veterinary Internal Medicine",
    ContactNumber: "+94 77 123 4567",
    BookingAvailableDates: [
      {
        Date: getFutureDate(1), //"JUNE 20 2024",
        Times: getFutureDate(1) + "T10:00:00",
        ActiveAppointments: 1
      },
      {
        Date: getFutureDate(1), //"JUNE 20 2024",
        Times: getFutureDate(1) + "T11:00:00",
        ActiveAppointments: 5
      },
    //   {
    //     Date: getFutureDate(1), //"JUNE 20 2024",
    //     Times: getFutureDate(1) + "T13:00:00",
    //     ActiveAppointments: 9
    //   },
    //   {
    //     Date: getFutureDate(2), //"JUNE 21 2024",
    //     Times: getFutureDate(2) + "T10:00:00",
    //     ActiveAppointments: 0
    //   },
      {
        Date: getFutureDate(2), //"JUNE 21 2024",
        Times: getFutureDate(2) + "T11:00:00",
        ActiveAppointments: 3
      },
      {
        Date: getFutureDate(2), //"JUNE 21 2024",
        Times: getFutureDate(2) + "T13:00:00",
        ActiveAppointments: 8
      },
    ],
    Charges: 100,
    Tax: 20,
  },
  {
    ID: 1,
    DoctorName: "Dr. Suresh Silva",
    Specialization: "Veterinary Surgery",
    ContactNumber: "+94 77 234 5678",
    BookingAvailableDates: [
      {
        Date: getFutureDate(3), //"JUNE 22 2024",
        Times: getFutureDate(3) + "T09:00:00",
        ActiveAppointments: 3
      },
    //   {
    //     Date: getFutureDate(3), //"JUNE 22 2024",
    //     Times: getFutureDate(3) + "T14:00:00",
    //     ActiveAppointments: 1
    //   },
    //   {
    //     Date: getFutureDate(3), //"JUNE 22 2024",
    //     Times: getFutureDate(3) + "T15:30:00",
    //     ActiveAppointments: 0
    //   },
    //   {
    //     Date: getFutureDate(4), //"JUNE 23 2024",
    //     Times: getFutureDate(4) + "T09:00:00",
    //     ActiveAppointments: 2
    //   },
    //   {
    //     Date: getFutureDate(4), //"JUNE 23 2024",
    //     Times: getFutureDate(4) + "T14:00:00",
    //     ActiveAppointments: 1
    //   },
      {
        Date: getFutureDate(4), //"JUNE 23 2024",
        Times: getFutureDate(4) + "T15:30:00",
        ActiveAppointments: 0
      },
    ],
    Charges: 100,
    Tax: 20,
  },
  {
    ID: 2,
    DoctorName: "Dr. Amara Fernando",
    Specialization: "Veterinary Internal Medicine",
    ContactNumber: "+94 77 345 6789",
    BookingAvailableDates: [
      {
        Date: getFutureDate(5), //"JUNE 24 2024",
        Times: getFutureDate(5) + "T09:00:00",
        ActiveAppointments: 9
      },
      {
        Date: getFutureDate(5), //"JUNE 24 2024",
        Times: getFutureDate(5) + "T14:00:00",
        ActiveAppointments: 1
      },
    //   {
    //     Date: getFutureDate(5), //"JUNE 24 2024",
    //     Times: getFutureDate(5) + "T15:30:00",
    //     ActiveAppointments: 0
    //   },
    //   {
    //     Date: getFutureDate(6),
    //     Times: getFutureDate(4) + "T03:00:00",
    //     ActiveAppointments: 0
    //   },
      {
        Date: getFutureDate(6),
        Times: getFutureDate(4) + "T10:00:00",
        ActiveAppointments: 1
      },
      {
        Date: getFutureDate(6),
        Times: getFutureDate(4) + "T15:30:00",
        ActiveAppointments: 8
      },
    ],
    Charges: 100,
    Tax: 20,
  },
  {
    ID: 3,
    DoctorName: "Dr. Lakmal Jayasinghe",
    Specialization: "Veterinary Surgery",
    ContactNumber: "+94 77 456 7890",
    BookingAvailableDates: [
      {
        Date: getFutureDate(6), //"JUNE 26 2024",
        Times: getFutureDate(6) + "T09:00:00",
        ActiveAppointments: 2

      },
    //   {
    //     Date: getFutureDate(6), //"JUNE 26 2024",
    //     Times: getFutureDate(6) + "T14:00:00",
    //     ActiveAppointments: 0
    //   },
    //   {
    //     Date: getFutureDate(6), //"JUNE 26 2024",
    //     Times: getFutureDate(6) + "T15:30:00",
    //     ActiveAppointments: 9
    //   },

    //   {
    //     Date: getFutureDate(7), //"JUNE 27 2024",
    //     Times: getFutureDate(7) + "T09:00:00",
    //     ActiveAppointments: 4
    //   },
      {
        Date: getFutureDate(7), //"JUNE 27 2024",
        Times: getFutureDate(7) + "T14:00:00",
        ActiveAppointments: 2
      },
      {
        Date: getFutureDate(7), //"JUNE 27 2024",
        Times: getFutureDate(7) + "T15:30:00",
        ActiveAppointments: 0
      },
    ],
    Charges: 100,
    Tax: 20,
  },
  {
    ID: 4,
    DoctorName: "Dr. Ruwan Senanayake",
    Specialization: "Veterinary Internal Medicine",
    ContactNumber: "+94 77 567 8901",
    BookingAvailableDates: [
      {
        Date: getFutureDate(8), //"JUNE 28 2024",
        Times: getFutureDate(8) + "T09:00:00",
        ActiveAppointments: 1
      },
    //   {
    //     Date: getFutureDate(8), //"JUNE 28 2024",
    //     Times: getFutureDate(8) + "T14:00:00",
    //     ActiveAppointments: 0
    //   },
    //   {
    //     Date: getFutureDate(8), //"JUNE 28 2024",
    //     Times: getFutureDate(8) + "T15:30:00",
    //     ActiveAppointments: 1
    //   },
      {
        Date: getFutureDate(9), //"JUNE 29 2024",
        Times: getFutureDate(9) + "T10:00:00",
        ActiveAppointments: 0
      },
      {
        Date: getFutureDate(9), //"JUNE 29 2024",
        Times: getFutureDate(9) + "T11:00:00",
        ActiveAppointments: 6
      },
      {
        Date: getFutureDate(9), //"JUNE 29 2024",
        Times: getFutureDate(9) + "T13:00:00",
        ActiveAppointments: 2
      },
    ],
    Charges: 100,
    Tax: 20,
  },
];

export const getData = () => {
  const tempdata = [...appointments];
  console.log(JSON.stringify(tempdata, null, 2));
  if(!verifyTimeFormat(tempdata[0].BookingAvailableDates[0].Times))
{
  tempdata.forEach((doctor) => {
    doctor.BookingAvailableDates.forEach((booking) => {
      booking.Date = formatDate(booking.Date);
      console.log(booking.Times[0]);
      booking.Times = formatTime(booking.Times);
    });
  });
}

  console.log(JSON.stringify(tempdata, null, 2));

  return tempdata;
};
