import { useState, useEffect } from "react";
import "./Result.css";
import { BadgeCard } from "../../Severity Badge/Badge";
import { useContext } from "react";
import { Context } from "../../Context/Context";
import Logo from "../../Utility/Logo/Logo";

const ResultPage = () => {
  const { Result, CropImage, responseImage } = useContext(Context);

  const [activeBadge, setActiveBadge] = useState({
    red: false,
    yellow: false,
    green: false,
  });
  const handdleClick = () => {
    console.log("clicked");
    window.location.pathname = "/";
  };

  const handdleAppoinmentClick = () => {
    console.log("clicked");
    window.location.pathname = "/doctor";
  };
  const changeActiveBadge = (color) => {
    if (color === "RED") {
      setActiveBadge({ red: true, yellow: false, green: false });
    } else if (color === "YELLOW") {
      setActiveBadge({ red: false, yellow: true, green: false });
    } else {
      setActiveBadge({ red: false, yellow: false, green: true });
    }
  };

  useEffect(() => {
    changeActiveBadge(Result.Color);
  }, [Result]);

  return (
    <div className="container">
      <Logo />
      <div className="row justify-content-center mt-5">
        <div className="col-sm-12 col-lg-5 col-md-6 align-item-center justify-content-center">
          
          <div>
            <p className="fs-4 text-center txt-black">Assessment of Condition Severity</p>
            <BadgeCard activeBadge={activeBadge} />
          </div>
        </div>
      </div>
      <div className="row justify-content-center mt-4">
        <div className="col-sm-12 col-lg-6 col-md-6 align-item-center justify-content-center">
          <div className="result_txt">
            {/* <h3>{"AI Analysis Result"}</h3> */}
            <p>
              {Result.Message}
            </p>
          </div>
        </div>
      </div>

      <div className="row justify-content-center mt-4">
        {/* <h3 className="Ai_Results_title">{"AI Analysis Details"}</h3> */}
        <div className="row  justify-content-center mb-2">
          {CropImage && (
            <div className="col-sm-12 col-lg-3 col-md-6 text-start mt-3 ">
              <p className="text-center fs-5 txt-black">Submitted Image</p>
              <img
                src={CropImage}
                id="upload"
                className="rounded float-start image result"
                alt="Original"
              ></img>
            </div>
          )}

          {responseImage && (
            <div className="col-sm-12 col-lg-3 col-md-6 text-start mt-3 ">
              <p className="text-center  fs-5 txt-black">Response Image</p>
              <img
                src={responseImage}
                id="upload"
                className="rounded float-start image result"
                alt="Response"
              ></img>
            </div>
          )}
        </div>
      </div>

      <div className="row justify-content-center">
        <div className="col-sm-12 col-md-6 col-lg-6 my-3">
          <div className="Ai_Results">
            <h3 className="Ai_Results_title mt-5">
              {"Vision AI Analysis Outcome"}
            </h3>
            <div className="Ai_Results_box">
              <div className="Ai_Results_box_item">
                <h3>{"Predicted Class"}</h3>
                <h3>{Result.Predicted_Class_Name}</h3>
              </div>
              {/* <div className="Ai_Results_box_item">
                <h3>{"Confident level"}</h3>
                <h3>{Result.Confidence_Score}</h3>
              </div> */}
              {/* <div className="Ai_Results_box_item">
                <h3>{"Overall Condition"}</h3>
                <h3>{Result.Condition}</h3>
              </div> */}
            </div>
          </div>
        </div>
      </div>

      <div className="row justify-content-center">
        <div className="col-sm-12  col-md-6 col-lg-6 my-3">
          <div className="Ai_Results">
            <h3 className="Ai_Results_title">{"AI Diagnostic Insights"}</h3>
            <div className="Ai_Results_box">
              <div className="Ai_Results_box_item">
                <h3>{"Health Status Indicator"}</h3>
                 <h3>{Result.Condition === "Not Normal" ? "Potential Issue Detected" : "No Issues Detected"}</h3>
              </div>
            </div>
           { Result.explanation ?(<div className="result_txt">
              <p>{Result.explanation}</p>
            </div>):("")}
          </div>
        </div>
      </div>

      <div className="row justify-content-center">
        <div className="col-12 col-md-4 col-lg-4 my-3">
          <div className="Ml_result mb-5">
            <button className="btn btn-purpole" onClick={(e) => handdleAppoinmentClick()}>
            {"Vet Appointment"}
             
            </button>
          </div>
        </div>

        <div className="col-12 col-md-4 col-lg-4 my-3">
          <div className="Ml_result mb-5">
            <button className="btn btn-purpole" onClick={(e) => handdleClick()}>
              {"View Detail Report"}
            </button>
          </div>
        </div>

        <div className="col-12 col-md-4 col-lg-4 my-3">
          <div className="Ml_result mb-5">
            <button className="btn btn-purpole" onClick={(e) => handdleClick()}>
            {"Submit New Image"}
              
            </button>
          </div>
        </div>

        <div className="col-12 col-md-4 col-lg-4 my-3">
          <div className="Ml_result mb-5">
            <button className="btn btn-purpole" onClick={(e) => window.open('https://pawsmart.ai.streamlit.app/',"_blank")}>
            {"Talk to PawSmart.ai"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResultPage;
