import "./ImageModel.css";
import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import React from "react";
import CloseButton from "react-bootstrap/CloseButton";
import Form from "react-bootstrap/Form";
import { useContext } from 'react';
import {Context} from '../Context/Context'

export function ModelSelect() {
  const {showselect,setshowselect,setonshow,setSelected} = useContext(Context);
  const data = [
    {
      id: 2,
      type: "radio",
      label: "Acceptance of Terms",
      description: "By using the Dog Skin Screening Tool, you agree to these terms. This tool offers preliminary assessments of dog skin conditions based on your inputs and is not a substitute for professional veterinary advice. The content is for general information only and remains the intellectual property of the tool's owner. You are responsible for the accuracy of information provided and obtaining appropriate veterinary care",
    },
    // {
    //   id: 3,
    //   type: "radio",
    //   label: "Acne Grade Meter",
    //   description: "Determine the severity of your acne. This tool grades your acne into three levels: Low, Medium, and High, helping you understand its intensity.",
    // },
    // {
    //   id: 1,
    //   type: "radio",
    //   label: "Back Acne Alert",
    //   description:"Specifically designed for back acne detection. This tool classifies whether the acne on your back is potentially dangerous or not"
    // },
  ]

  const handlechange = (event) => {

    event.preventDefault();
    event.stopPropagation();

    setshowselect(false);
    setonshow(true);
    setSelected(event.target.id);
  };

  const handleClose = () => {
    setshowselect(false);
  };
  return (
    <>
      <Modal
        show={showselect}
        onHide={() => handleClose}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="bg-white">
          <Modal.Title id="contained-modal-title-vcenter" className="txt-white">
            <p className="fw-bold fs-4 text-purpole title"> Terms and Conditions </p>
          </Modal.Title>
          <CloseButton onClick={handleClose} />
        </Modal.Header>
        <Modal.Body className="bg-white">
          <Container className=" justify-content-center">
            <Row className="justify-content-center ">
              <Col
                s={12}
                md={12}
                lg={12}
                className="justify-content-center align-items-center mt-2"
              >
                <Form>
                  {data.map((data) => (
                    <div key={`default-${data.id}`} onChange={(e)=>handlechange(e)} className="mb-4 corsor">
                      <Form.Check
                        type={data.type}
                        id={data.id}
                        label={`${data.label}`}
                        name="model"
                        className="fs-5 text-purpole corsor"
                      />
                      <p className="fs-7 ms-4" id={data.id} onClick={(e)=>handlechange(e)}>{data.description}</p>
                 
                    </div>
                  ))}
                </Form>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
}
