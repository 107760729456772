
// import logo from '../../Crop Component/Logo/logo.png'
import Logo from '../../Utility/Logo/Logo';
import '../../../App.css';
import {From} from '../../Form/Form'
import { useNavigate } from "react-router-dom";
import { useEffect } from 'react';
import {verifyuser} from  '../../APIHelper/APIHelper'
import { FaUserLarge } from "react-icons/fa6";
import { PiDog } from "react-icons/pi";
import './Intro.css'

const Intro = () => {
    const navigate =  useNavigate();
    const scanHanddle = ()=>{
        navigate('home')
    }

    useEffect(()=>
    {
      const res =  verifyuser()
      console.log(res)
      if(!res)
      {
        navigate('login')
      }
    },[])

    return (
        <header className="App-header ">
      
        {/* <img src={logo} className="App-logo mt-3" alt="logo" /> */}
        <Logo />
        <div className="container">
            <div className="row justify-content-center">
            <div className="txt mb-3">
              <p className="fs-5 text-purpole">Welcome Pet Parent!</p>
              <p className="fs-5 fw-lighter">
<span className='text-purpole'>PawSmart.ai </span> is a personalized AI-based platform for your pets' healthcare and management. Take care of your furry partner with ease, ensuring they stay happy and healthy.</p>
           </div>
  
          </div>
  
          <div className="row justify-content-center align-items-center  text-center mb-5">

          <div className='col-12 col-md-4 col-lg-4 mt-5' onClick={scanHanddle}>
                <div className='card'>

                <div className='intro-icon'>
                <PiDog className='text-purpole' size={100} />
                </div>
                <div className='intro-topic text-purpole mt-4'>
                    Perform a scan 

                </div>
                </div>

            </div>

            <div className='col-12 col-md-4 col-lg-4 mt-5'>
                <div className='card'>

                <div className='intro-icon'>
                <FaUserLarge className='text-purpole' size={100} />
                </div>
                <div className='intro-topic text-purpole mt-4'>
                    Update User Profile 

                </div>
                </div>

            </div>

          </div>
           
          </div>
    
         
        </header>
    )
}

export default Intro