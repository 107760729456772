
// import logo from '../../Crop Component/Logo/logo.png'
import Logo from '../../Utility/Logo/Logo';
import '../../../App.css';
import {From} from '../../Form/Form'
import { useNavigate } from "react-router-dom";
import { useEffect } from 'react';
import {verifyuser} from  '../../APIHelper/APIHelper'

const Home = () => {
    const navigate =  useNavigate();
    useEffect(()=>
    {
      const res =  verifyuser()
      console.log(res)
      if(!res)
      {
        navigate('login')
      }
    },[])

    return (
        <header className="App-header ">
      
        {/* <img src={logo} className="App-logo mt-3" alt="logo" /> */}
        <Logo />
        <div className="container">
            <div className="row justify-content-center">
            <div className="txt mb-3">
              <p className="fs-5">Your Dog's Health in Your Hands</p>
              <p className="fs-5 fw-lighter">Healthy Skin, Happy Pup!🐾 - Snap, Check, relief!✨</p>
           </div>
  
          </div>
  
             <From />
          </div>
    
         
        </header>
    )
}

export default Home