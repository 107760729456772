
import './App.css';
import {LoadingSpinner} from './Components/Form/Form'
import { useContext } from 'react';
import {Context} from './Components/Context/Context'
import { Routes, Route } from 'react-router-dom'
import Home from './Components/Pages/Home Page/Home'
import Result from './Components/Pages/Result Page/Result'
import Login from './Components/Pages/LoginPage/Login';
import Register from './Components/Pages/Register Page/Register';
import NavBarLogOut from "./Components/NavBar/NavBar";
import DoctorList from './Components/Doctors/DoctorList';
import Intro from './Components/Pages/Intro Page/Intro';
import QuestionPage from './Components/Pages/QuestionPage/QuestionPage';
function App() {
   
  const {isLoading} = useContext(Context);

  return (
    <>
    {/* <div className="App"> */}
         {isLoading?<LoadingSpinner />:""}
    {/* </div> */}

    
    <div className="d-flex ">
    <NavBarLogOut />

    </div>
    
    <Routes>
    <Route path='/login' element={<Login />} index  />
    <Route path='/register' element={<Register />}   />
    <Route path='/home' element={<QuestionPage />}   />
    <Route path='/' element={<Intro />}   />
    <Route path='/result' element={<Result />} />
    <Route path='/doctor' element={<DoctorList />} />
  </Routes>
  </>

  );
}

export default App;
