import { createContext,useState } from "react";
import { useNavigate } from "react-router-dom";
import {getToken} from '../APIHelper/APIHelper'
export const Context = createContext();


function ContextProvider({ children }) {

    const [uploadImage, setUploadImage] = useState(null);
    const [responseImage, setresponseImage] = useState(null);
    const [Result, SetResult] = useState(null);
    const [onshow, setonshow] = useState(false);
    const [CropImage, SetCropImage] = useState(null);
    const [finalAnswers,setfinalAnswers] = useState({});
    const [imgDimention, setimgDimention] = useState({
      height: 0,
      width: 0,
    });
    const [Pain,setPain] = useState("0");
    // const [discharge,setdischarge] = useState(null);
    const [Imageshow, setImageshow] = useState(false);
    const [instruction,setinstuction] = useState(false)
    const [showselect,setshowselect] = useState(false)
    const [selected,setSelected] = useState(0)
    const navigate = useNavigate();


  // const BASE_URL = process.env.REACT_APP_BASE_URL + "/diagnosis_scan";
  const BASE_URL = 'https://api.stubby.online' + "/diagnosis_scan";

  console.log(BASE_URL);

   const [isLoading, setIsLoading] = useState(false);


  
  const SendData = (CroppedImage,Answers) => {
   
    resetSetting();
  
   if (CroppedImage !== null) {
     SetCropImage(CroppedImage);
     setIsLoading(true);
     const data = { Image: CroppedImage,model_version:selected,diagnosis_responses:Answers};
     console.log(data);
     const requestOptions = {
       method: 'POST', // or 'PUT'
       headers: {
         'Content-Type': 'application/json',
         "Authorization" : "Bear "+ getToken(),
       },
       body: JSON.stringify(data),
     }

     fetch(BASE_URL, requestOptions)
       .then((response) => response.json())
       .then((data) => {
   
        setIsLoading(false);
         data.result.confidance_score = data.result.confidance_score+ " %"
         console.log(data.result);
         SetResult(data.result);
         if(data.result.output_img){setresponseImage("data:image/jpeg;base64,"+data.result.output_img);}
         navigate('/result');

       });
   }
   
 };

 const resetSetting = () => {
    setresponseImage(null);
    SetResult(null);
  };

  const value = {
    uploadImage,
    setUploadImage,
    responseImage,
    setresponseImage,
    Result,
    SetResult,
    onshow,
    setonshow,
    CropImage,
    SetCropImage,
    imgDimention,
    setimgDimention,
    Pain,
    setPain,
    Imageshow,
    setImageshow,
    instruction,
    setinstuction,
    showselect,
    setshowselect,
    selected,
    setSelected,
    SendData,
    resetSetting,
    isLoading,
    setIsLoading,
    finalAnswers,
    setfinalAnswers
}
 

  return (
    <Context.Provider value={value}>
        {children}
    </Context.Provider>
);

}

export default ContextProvider;
