
// import "./ImageModel.css"
import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import React, { useState} from "react";
import CloseButton from 'react-bootstrap/CloseButton';
// import logo from '../Logo IMG/logo1.png'
// import imageerr from "./Image.svg"

// import { CiBookmark } from "react-icons/ci";
import { IoIosSend } from "react-icons/io";
import './Doctor.css'

export function DoctorPayment({sessionPayment,setPaymentshow,Data, sessionindex})
{
    const [show, setShow] = useState(sessionPayment);

    const handleClose = () => {
        setShow(false);
        setPaymentshow(false)
      
      };
    return (
        <>
        
          <Modal
            show={show}
            onHide={() => handleClose}
            size="md"
            // dialogClassName="modal-90w"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header className="bg-white">
              <Modal.Title id="contained-modal-title-vcenter" className="text-purpole">
               Doctor Payment
              </Modal.Title>
              <CloseButton  onClick={handleClose}/>
            </Modal.Header>
            <Modal.Body className="bg-white">
              <Container className=" justify-content-center">
              <Row className="justify-content-center ">
              <Col s={4} md={4} lg={6} className="justify-content-center align-items-center mt-2">
                    <p className="fs-5 text-purpole">DOCTOR NAME</p>
             </Col>
             <Col s={4} md={4} lg={6} className="justify-content-center align-items-center mt-2">
                    <p className='fs-5 text-black'> {Data.DoctorName}</p>
             </Col>
             </Row>
             <Row className="justify-content-center ">
              <Col s={4} md={4} lg={6} className="justify-content-center align-items-center mt-2">
                    <p className="fs-5 text-purpole">SPECIALIZATION</p>
             </Col>
             <Col s={4} md={4} lg={6} className="justify-content-center align-items-center mt-2">
                    <p className='fs-5 text-black'> {Data.Specialization}</p>
             </Col>
             </Row>

             <Row className="justify-content-center ">
              <Col s={4} md={4} lg={6} className="justify-content-center align-items-center mt-2">
                    <p className="fs-5 text-purpole">CHENNEL DATE</p>
             </Col>
             <Col s={4} md={4} lg={6} className="justify-content-center align-items-center mt-2">
                    <p className='fs-5 text-black'> {Data.BookingAvailableDates[sessionindex].Date}</p>
             </Col>
             </Row>

             <Row className="justify-content-center ">
              <Col s={4} md={4} lg={6} className="justify-content-center align-items-center mt-2">
                    <p className="fs-5 text-purpole">SESSION</p>
             </Col>
             <Col s={4} md={4} lg={6} className="justify-content-center align-items-center mt-2">
                    <p className='fs-5 text-black'> {Data.BookingAvailableDates[sessionindex].Times}</p>
             </Col>
             </Row>

             <Row className="justify-content-center ">
              <Col s={4} md={4} lg={6} className="justify-content-center align-items-center mt-2">
                    <p className="fs-5 text-purpole">PAYMENT</p>
             </Col>
             <Col s={4} md={4} lg={6} className="justify-content-center align-items-center mt-2">
                    <p className='fs-5 text-black'> ${Data.Charges}</p>
             </Col>
             </Row>

             <Row className="justify-content-center ">
              <Col s={4} md={4} lg={6} className="justify-content-center align-items-center mt-2">
                    <p className="fs-5 text-purpole">TAX</p>
             </Col>
             <Col s={4} md={4} lg={6} className="justify-content-center align-items-center mt-2">
                    <p className='fs-5 text-black'> ${Data.Tax}</p>
             </Col>
             </Row>

             <Row className="justify-content-center ">
              <Col s={4} md={4} lg={6} className="justify-content-center align-items-center mt-2">
                    <p className="fs-5 text-purpole">TOTLE</p>
             </Col>
             <Col s={4} md={4} lg={6} className="justify-content-center align-items-center mt-2">
                    <p className='fs-5 text-black'> ${Data.Tax+Data.Charges}</p>
             </Col>
             </Row>

             <Row className="justify-content-center align-items-center">
                 
             {/* <div className='channel'> */}
                                <button className='btn btn-purpole'>
                                    <IoIosSend className='text-white' />
                                    <span>
                                         Make Payment
                                    </span>
                                </button>
                            {/* </div> */}
             </Row>

              </Container>
            </Modal.Body>
          </Modal>
        </>
      );
}