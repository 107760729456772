
// import logo from '../../Crop Component/Logo/logo.png'
import Logo from '../../Utility/Logo/Logo';
import '../../../App.css';
import { useNavigate } from "react-router-dom";
import { useEffect } from 'react';
import {verifyuser} from  '../../APIHelper/APIHelper'
import Questions from '../../QuestionList/Question';

// import './Intro.css'

const QuestionPage = () => {
    const navigate =  useNavigate();
    // const scanHanddle = ()=>{
    //     navigate('home')
    // }

    useEffect(()=>
    {
      const res =  verifyuser()
      console.log(res)
      if(!res)
      {
        navigate('login')
      }
    },[])

    return (
        <header className="App-header ">
      
        {/* <img src={logo} className="App-logo mt-3" alt="logo" /> */}
        <Logo />
        <div className="container">
            <div className="row justify-content-center">
            <div className="txt mb-3">
              <p className="fs-5 text-purpole">PawSmart.ai Assesment</p>
           </div>
  
          </div>


          <div className="row justify-content-center mb-3">
          <div className="col-12 col-md-8 col-lg-8">
          <div className='card'>
            

          <Questions />

          </div>
          </div>
          </div>
         
           
        </div>
    
         
        </header>
    )
}

export default QuestionPage